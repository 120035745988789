<template>
  <div>
    <b-table
      :fields="tableFields"
      :items="dictTreeLocal"
      responsive="false"
      bordered
      sticky-header="true"
      :filter="filter.search"
      :filter-included-fields="filter.on"
      :tbody-tr-class="rowClass"
    >
      <template #head(pcl)="scope">
        <div align="center">ПКЛ</div>
        <br />
        <b-form-input
          id="filter-input"
          v-model="filter.pcl"
          type="search"
        ></b-form-input>
      </template>

      <template #head(spf)="scope">
        <div align="center">СПК</div>
        <br />
        <b-form-input
          id="filter-input"
          v-model="filter.spf"
          type="search"
        ></b-form-input>
      </template>

      <template #head(name_ru)="scope">
        <div align="center">Наименование на русском языке</div>
        <br />

        <b-form-input
          id="filter-input"
          v-model="filter.name_ru"
          type="search"
        ></b-form-input>
      </template>

      <template #cell(action)="data">
        <b-button
          v-if="data.item.type === 3"
          class="text-right"
          @click="openChilds(data.item, data.index)"
        >
          <i
            class="icon icon-keyboard icon-rotate-270"
            v-if="data.item.open"
          ></i>
          <i
            class="icon icon-keyboard icon-rotate-90"
            v-if="!data.item.open"
          ></i>
        </b-button>
      </template>

      <template #cell(pcl)="data">
        <div v-if="data.item.type === 3">{{ data.item.pcl }}</div>
        <div v-if="data.item.type === 4">-</div>
      </template>
      <template #cell(spf)="data">
        <div v-if="data.item.type === 4">{{ data.item.spf }}</div>
        <div></div>
      </template>

      <template class="tr" #cell(beg_date)="data">
        <div style="white-space: nowrap">
          {{ millisToDate(data.item.beg_date) }}
        </div>
      </template>

      <template class="tr" #cell(end_date)="data">
        <div class="tr">
          {{ millisToDate(data.item.end_date) }}
        </div>
      </template>

      <template #cell(add)="data">
        <b-dropdown id="dropdown-dropleft" dropleft class="more">
          <template v-slot:button-content>
            <i class="icon icon-more"></i>
          </template>
          <template>
            <b-dropdown-item :disabled="!UserRoles.includes('dict_editor')"  @click="openModalAndUpdateForm(data.item)"
              >Редактировать</b-dropdown-item
            >
            <b-dropdown-item :disabled="!UserRoles.includes('dict_editor')" @click="openModalSave(data.item, data.index)"
              >Добавить</b-dropdown-item
            >
            <b-dropdown-item
            :disabled="!UserRoles.includes('dict_editor')"
              v-if="data.item.type !== 4"
              @click="openModalSaveChild(data.item)"
              >Добавить (дочерний)
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>
    </b-table>
    <b-modal
      @hidden="CleanItem"
      id="modal-prevent-closing"
      ref="my-modal"
      centered
      size="lg"
      content-class="modal-forms"
      title="Добавить / Редактировать"
    >
      <b-form-group
        disabled
        style="font-weight: bold"
        label="Код категории (КАТ)"
        label-for="title-input"
        label-align-sm="left"
        class="form-flex"
      >
        <b-form-input
          :disabled="true"
          type="number"
          v-model="NewItem.kat"
          id="title-input"
          no-resize
          rows="2"
          max-rows="2"
          class="form-input"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        disabled
        style="font-weight: bold"
        label="Код класса (КЛ)"
        label-for="title-input"
        label-align-sm="left"
        class="form-flex"
      >
        <b-form-input
          :disabled="true"
          type="number"
          v-model="NewItem.cls"
          id="title-input"
          no-resize
          rows="2"
          max-rows="2"
          class="form-input"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        disabled
        style="font-weight: bold"
        label="Код подкласса (ПКЛ)"
        label-for="title-input"
        label-align-sm="left"
        class="form-flex"
      >
        <b-form-input
          :disabled="
            (NewItem.id !== undefined && NewItem.type === 3) ||
            NewItem.type === 4
              ? true
              : false
          "
          type="number"
          debounce="500"
          v-model="NewItem.pcl"
          id="title-input"
          no-resize
          rows="2"
          max-rows="2"
          :class="$v.NewItem.pcl.$error ? 'is-invalid' : ''"
          class="form-input"
        ></b-form-input>
        <p
          v-if="$v.NewItem.pcl.$dirty && !$v.NewItem.pcl.required"
          class="invalid-feedback"
        >
          Обязательное поле
        </p>

        <p
          v-if="$v.NewItem.pcl.$dirty && !$v.NewItem.pcl.minLength"
          class="invalid-feedback"
        >
          Число в поле должно быть трехзначным !
        </p>

        <p
          v-if="$v.NewItem.pcl.$dirty && !$v.NewItem.pcl.maxLength"
          class="invalid-feedback"
        >
          Число в поле должно быть трехзначным !
        </p>

        <p
          v-if="$v.NewItem.pcl.$dirty && !$v.NewItem.pcl.UniquePcl"
          class="invalid-feedback"
        >
          Данный код подкласса (ПКЛ) существует !
        </p>
      </b-form-group>

      <b-form-group
        style="font-weight: bold"
        label="Код специфики (СПК)"
        label-for="title-input"
        label-align-sm="left"
        class="form-flex"
      >
        <b-form-input
          :disabled="
            (NewItem.id !== undefined && NewItem.type === 4) ||
            NewItem.type === 3
              ? true
              : false
          "
          type="number"
          debounce="500"
          v-model="NewItem.spf"
          id="title-input"
          no-resize
          rows="2"
          :class="$v.NewItem.spf.$error ? 'is-invalid' : ''"
          max-rows="2"
          class="form-input"
        ></b-form-input>
        <p
          v-if="$v.NewItem.spf.$dirty && !$v.NewItem.spf.required"
          class="invalid-feedback"
        >
          Обязательное поле
        </p>

        <p
          v-if="$v.NewItem.spf.$dirty && !$v.NewItem.spf.minLength"
          class="invalid-feedback"
        >
          Число в поле должно быть трехзначным !
        </p>

        <p
          v-if="$v.NewItem.spf.$dirty && !$v.NewItem.spf.maxLength"
          class="invalid-feedback"
        >
          Число в поле должно быть трехзначным !
        </p>

        <p
          v-if="$v.NewItem.spf.$dirty && !$v.NewItem.spf.UniqueSpf"
          class="invalid-feedback"
        >
          Данный код специфики (СПК) существует !
        </p>
      </b-form-group>

      <b-form-group
        v-if="NewItem.id !== undefined"
        style="color: rgb(8, 2, 1)"
        label="Код"
        label-for="title-input"
        label-align-sm="left"
        class="form-flex"
      >
        <b-form-input
          type="text"
          v-model="NewItem.full_code"
          id="title-input"
          no-resize
          rows="2"
          max-rows="2"
          class="form-input"
          :disabled="
            NewItem.id !== undefined ||
            NewItem.type === 1 ||
            NewItem.type === 2 ||
            NewItem.type === 3 ||
            NewItem.type === 4 ||
            NewItem.type === 5
              ? true
              : false
          "
        ></b-form-input>
      </b-form-group>

      <b-form-group
        style="color: rgb(8, 2, 1)"
        label="Наименование на Русском языке "
        label-for="title-input"
        label-align-sm="left"
        class="form-flex"
      >
        <b-form-textarea
          v-model.trim="NewItem.name_ru"
          id="title-input"
          no-resize
          rows="2"
          max-rows="2"
          :class="$v.NewItem.name_ru.$error ? 'is-invalid' : ''"
          class="form-input"
        ></b-form-textarea>
        <p
          v-if="$v.NewItem.name_ru.$dirty && !$v.NewItem.name_ru.required"
          class="invalid-feedback"
        >
          Обязательное поле
        </p>
      </b-form-group>

      <b-form-group
        style="color: rgb(8, 2, 1)"
        label="Наименование на Казахском языке"
        label-for="title-input"
        label-align-sm="left"
        class="form-flex"
      >
        <b-form-textarea
          v-model.trim="NewItem.name_kk"
          id="title-input"
          no-resize
          rows="2"
          :class="$v.NewItem.name_kk.$error ? 'is-invalid' : ''"
          max-rows="2"
          class="form-input"
        ></b-form-textarea>
        <p
          v-if="$v.NewItem.name_kk.$dirty && !$v.NewItem.name_kk.required"
          class="invalid-feedback"
        >
          Обязательное поле
        </p>
      </b-form-group>

      <b-form-group
        style="font-weight: bold"
        label="Дата начала"
        label-for="title-input"
        label-align-sm="left"
        class="form-flex"
      >
        <b-form-input
          type="date"
          v-model="NewItem.beg_date"
          id="title-input"
          no-resize
          rows="2"
          max-rows="2"
          class="form-input"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        style="font-weight: bold"
        label="Дата завершения"
        label-for="title-input"
        label-align-sm="left"
        class="form-flex"
      >
        <b-form-input
          type="date"
          v-model="NewItem.end_date"
          id="title-input"
          no-resize
          rows="2"
          max-rows="2"
          class="form-input"
        ></b-form-input>
      </b-form-group>

      <template #modal-footer>
        <b-button type="submit" variant="success" @click="checkModalForm">
          Сохранить
        </b-button>
        <b-button variant="danger" @click="hideModal">Закрыть</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/services/store';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import Index from '../budget-execution/Index.vue';
export default {
    props: {
        dictEco: null
    },
    mixins: [validationMixin],
    data() {
        return {
            UserRoles: store.state.user.roles,
            dictTreeLocal: JSON.parse(JSON.stringify(this.dictEco)),
            NewItem: {},
            OriginItem: {},
            Index: null,

            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },

                {
                    key: 'pcl',
                    label: 'ПКЛ'
                },
                {
                    key: 'spf',
                    label: 'СПК'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование-Рус'
                },

                {
                    key: 'name_kk',
                    label: 'Наименование на казахском языке'
                },
                {
                    key: 'beg_date',
                    label: 'Дата начала'
                },

                {
                    key: 'end_date',
                    label: 'Дата завершения'
                },

                {
                    key: 'add',
                    label: ' '
                },

                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],

            selectedTree: [],
            budgetForm: [],
            open1: null,
            isUnique: false,

            filter: {
                pcl: null,
                spf: null,
                name_ru: null,
                search: null,
                on: ['filter', 'pcl', 'spf', 'name_ru']
            }
        };
    },

    created() {
        this.$watch('filter.pcl', this.filterUpdate);
        this.$watch('filter.spf', this.filterUpdate);
        this.$watch('filter.name_ru', this.filterUpdate);
    },

    validations() {
        if (this.NewItem.id !== undefined) {
            return {
                NewItem: {
                    kat: {},
                    cls: {},
                    pcl: {},
                    spf: {},
                    name_ru: { required },
                    name_kk: { required }
                }
            };
        }

        if (this.NewItem.type === 3) {
            return {
                NewItem: {
                    kat: {},
                    cls: {},
                    pcl: {
                        UniquePcl(value) {
                            fetch(
                                '/api/dict/dict_ebk_ek/Unique-Pcl-within-period?kat='
                  + this.NewItem.kat
                  + '&cls='
                  + this.NewItem.cls
                  + '&pcl='
                  + value
                  + '&type='
                  + this.NewItem.type
                  + (this.NewItem.beg_date ? `&begDate=${this.NewItem.beg_date}` : '')
                            )
                                .then((response) => {
                                    return response.json();
                                })
                                .then((Data) => {
                                    if (Data.length === 0 || Data.length === undefined) {
                                        this.isUnique = true;
                                        console.log(Data);
                                    } else {
                                        this.isUnique = false;
                                        console.log(Data);
                                    }
                                });
                            return this.isUnique;
                        },
                        required,
                        minLength: minLength(3),
                        maxLength: maxLength(3)
                    },
                    spf: {},
                    name_ru: { required },
                    name_kk: { required }
                }
            };
        }

        if (this.NewItem.type === 4) {
            return {
                NewItem: {
                    kat: {},
                    cls: {},
                    pcl: {},
                    spf: {
                        UniqueSpf(value) {
                            fetch('/api/dict/dict_ebk_ek/Unique-Spf-within-period?kat='
                             + this.NewItem.kat
                           + '&cls='
                           + this.NewItem.cls
                            + '&pcl='
                            + this.NewItem.pcl
                            + '&spf='
                            + value
                            + '&type='
                            + this.NewItem.type
                            + (this.NewItem.beg_date ? `&begDate=${this.NewItem.beg_date}` : '')


                            )
                                .then((response) => {
                                    return response.json();
                                })
                                .then((Data) => {
                                    if (Data.length === 0 || Data.length === undefined) {
                                        this.isUnique = true;
                                    } else {
                                        this.isUnique = false;
                                    }
                                });
                            return this.isUnique;
                        },

                        required,
                        minLength: minLength(3),
                        maxLength: maxLength(3)
                    },
                    name_ru: { required },
                    name_kk: { required }
                }
            };
        }

        if (this.NewItem.type === undefined) {
            return {
                NewItem: {
                    kat: {},
                    cls: {},
                    pcl: {},
                    spf: {},
                    name_ru: {},
                    name_kk: {}
                }
            };
        }
    },

    methods: {
        checkModalForm() {
            this.$v.NewItem.$touch();
            if (!this.$v.NewItem.$error) {
                this.SaveOrUpdate();
            }
        },

        SaveOrUpdate: function () {
            if (this.NewItem.id === undefined) {
                this.saveToServer();
            } else {
                this.updateItem();
            }
        },

        async updateItem() {
            await fetch('/api/dict/dict_ebk_ek', {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                body: JSON.stringify(this.NewItem)
            });
            this.CleanItem();
            this.hideModal();
            this.Resetload();
        },

        makeToast(title, variant) {
            this.$bvToast.toast(title, {
                title: 'Сообщение',
                variant: variant,
                solid: true
            });
        },

        creatItemToSave(item) {
            switch (item.type) {
                case 3:
                    this.NewItem.kat = item.kat;
                    this.NewItem.cls = item.cls;
                    this.NewItem.type = item.type;
                    break;
                case 4:
                    this.NewItem.kat = item.kat;
                    this.NewItem.cls = item.cls;
                    this.NewItem.pcl = item.pcl;
                    this.NewItem.type = item.type;
                    break;
                default:
            }
        },
        CleanItem: function () {
            this.NewItem = {};
            this.OriginItem = {};
        },
        openModalSave(item, index) {
            this.CleanItem();
            this.OriginItem = JSON.parse(JSON.stringify(item));
            this.creatItemToSave(item);
            this.Index = item;
            this.$v.NewItem.$touch();
            this.showModal();
        },

        creatItemToSaveChils(item) {
            switch (item.type) {
                case 3:
                    this.NewItem.kat = item.kat;
                    this.NewItem.cls = item.cls;
                    this.NewItem.pcl = item.pcl;
                    this.NewItem.type = item.type + 1;
                    break;

                default:
            }
        },

        openModalSaveChild(item) {
            this.CleanItem();
            this.OriginItem = JSON.parse(JSON.stringify(item));
            this.creatItemToSaveChils(item);
            this.Index = item;
            this.$v.NewItem.$touch();
            this.showModal();
        },

        openModalAndUpdateForm(item) {
            this.NewItem = JSON.parse(JSON.stringify(item));
            this.OriginItem = JSON.parse(JSON.stringify(item));
            this.showModal();
        },

        showModal() {
            this.$refs['my-modal'].show();
        },
        hideModal() {
            this.$refs['my-modal'].hide();
        },

        filterUpdate() {
            this.filter.search
        = (this.filter.pcl === null ? '' : this.filter.pcl)
        + (this.filter.spf === null ? '' : this.filter.spf)
        + (this.filter.name_ru === null ? '' : this.filter.name_ru);
            if (this.filter.search.length === 0) {
                this.filter.search = null;
            }
        }, // формирует строку поиска

        async loadParentFetch(Parent) {
            if (Parent.open === true && Parent.type === 3) {
                const response = await fetch(
                    '/api/dict/dict_ebk_ek/pcl-type?pcl=' + Parent.pcl + '&type=' + 4
                );
                this.selectedTree = await response.json();
                this.selectedTree.forEach(function (child) {
                    child.open = false;
                    child.visible = true;
                });
                const searchIndex = Parent.id;
                const index = this.dictTreeLocal.findIndex(
                    (el) => el.id === searchIndex
                );
                this.selectedTree.forEach((data) => {
                    this.dictTreeLocal.splice(index + 1, 0, data);
                });
            } else if (Parent.open === false && Parent.type === 3) {
                this.dictTreeLocal = this.dictTreeLocal.filter(
                    (child) => !(child.pcl === Parent.pcl && child.type === 4)
                );
            }
        },

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }

            if (item.type === 4) {
                return ' table-primary';
            }
        },

        openChilds(item) {
            if (item.open === false) {
                item.open = true;
                this.loadParentFetch(item);

                this.selectedTree = [];
            } else if (item.open === true) {
                item.open = false;
                this.loadParentFetch(item);
                this.selectedTree = [];
            }
        },
        Resetload: function () {
            this.$emit('Resetload');
        },
        saveToServer() {
            switch (this.NewItem.type) {
                case 3:
                    this.NewItem.full_code
            = '000' + this.NewItem.kat + this.NewItem.cls + this.NewItem.pcl;
                    break;
                case 4:
                    this.NewItem.full_code
            = String(this.NewItem.kat)
            + String(this.NewItem.cls)
            + String(this.NewItem.pcl)
            + String(this.NewItem.spf);
                    break;

                default:
            }

            fetch('/api/dict/dict_ebk_ek', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(this.NewItem)
            }).then((response) => {
                if (response.status === 200 && response.ok) {
                    this.makeToast('Обьект удачно сохранен', 'success');
                } else {
                    this.makeToast('Ошибка сохранения!', 'danger');
                }
            });
            this.CleanItem();
            this.hideModal();
            this.Resetload();
        },

        millisToDate: function (millis) {
            if (!millis) {
                return '';
            }
            const date = new Date(millis);
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month.toString().length === 1) {
                month = '0' + month;
            }
            let day = date.getDate();
            if (day.toString().length === 1) {
                day = '0' + day;
            }
            return year + '-' + month + '-' + day;
        }
    }
};
</script>

<style>
</style>