<template>
  <div>
    <b-table
      :fields="tableFields"
      :items="dictTreeLocal"
      responsive="true"
      bordered
      sticky-header="true"
      :filter="Search"
      :filter-included-fields="'budgetLevelId'"
      :tbody-tr-class="rowClass" >
      <template #head(gr)="scope">
        <div align="center">ФГ</div>
      </template>

      <template #head(pgr)="scope">
        <div align="center">ФПГ</div>

      </template>

      <template #head(abp)="scope">
        <div align="center">АБП</div>

        <b-form-input
          id="filter-input"
          v-model="filter.abp"
          type="search"
        ></b-form-input>
      </template>

      <template #head(prg)="scope">
        <div align="center">БП</div>
        <b-form-input
          id="filter-input"
          v-model="filter.prg"
          type="search"
        ></b-form-input>
      </template>

      <template #head(ppr)="scope">
        <div align="center">БПП</div>


        <b-form-input
          id="filter-input"
          v-model="filter.ppr"
          type="search"
        ></b-form-input>
      </template>

      <template #head(name_ru)="scope">

        <div align="center">Наименование на русском </div>

      </template>


      <template #head(budgetLevelId)="scope">
        <div align="center">УРОВЕНЬ БЮДЖЕТА</div>

        <b-form-select
          id="filter-input"
             v-model="Search"
             type="search"
          >
             <b-form-select-option value="">Не выбрано</b-form-select-option>
             <b-form-select-option value="1">РБ</b-form-select-option>
             <b-form-select-option value="2">ОБ</b-form-select-option>
             <b-form-select-option value="4">Бюджет МСУ</b-form-select-option>
             <b-form-select-option value="3">Рай.бюджет</b-form-select-option>
          </b-form-select>

      </template>

      <template #cell(action)="data">
        <b-button
          v-if="data.item.type === 1"
          class="text-right"
          @click="openChilds(data.item, data.index)"
        >
          <i
            class="icon icon-keyboard icon-rotate-270"
            v-if="data.item.open"
          ></i>
          <i
            class="icon icon-keyboard icon-rotate-90"
            v-if="!data.item.open"
          ></i>
        </b-button>
      </template>

      <template #cell(gr)="data">
        <div v-if="data.item.type === 1 || data.item.type === 3 || data.item.type === 4 ">{{ data.item.gr }}</div>
        <b-button
          v-if="data.item.type === 2"
          @click="openChilds(data.item, data.index)"
        >
          <i
            class="icon icon-keyboard icon-rotate-270"
            v-if="data.item.open"
          ></i>
          <i
            class="icon icon-keyboard icon-rotate-90"
            v-if="!data.item.open"
          ></i>
        </b-button>
      </template>

      <template #cell(pgr)="data">
        <div v-if="data.item.type === 2">{{ data.item.pgr }}</div>

        <b-button
          v-if="data.item.type === 3"
          @click="openChilds(data.item, data.index)"
        >
          <i
            class="icon icon-keyboard icon-rotate-270"
            v-if="data.item.open"
          ></i>
          <i
            class="icon icon-keyboard icon-rotate-90"
            v-if="!data.item.open"
          ></i>
        </b-button>
      </template>

      <template #cell(abp)="data">

        <div v-if="data.item.type === 3 || data.item.type === 5" >{{ data.item.abp }}</div>
        <b-button
          v-if="data.item.type === 4"
          @click="openChilds(data.item, data.index)"
        >
          <i
            class="icon icon-keyboard icon-rotate-270"
            v-if="data.item.open"
          ></i>
          <i
            class="icon icon-keyboard icon-rotate-90"
            v-if="!data.item.open"
          ></i>
        </b-button>
      </template>

      <template #cell(prg)="data">
        <div v-if="data.item.type === 3 && data.item.prg === false">
          Нет подпрограмм
        </div>
        <div v-if="data.item.type !== 3 && data.item.prg !== false">
          {{ data.item.prg }}
        </div>
      </template>

      <template #cell(ppr)="data">
        <div v-if="data.item.type === 4 && data.item.ppr === false">
          Нет подпрограмм
        </div>
        <div v-if="data.item.type !== 4 && data.item.ppr !== false">
          {{ data.item.ppr }}
        </div>
      </template>

      <template class="tr" #cell(begDate)="data">
        <div style="white-space: nowrap">
          {{ millisToDate(data.item.begDate) }}
        </div>
      </template>

      <template class="tr" #cell(endDate)="data">
        <div class="tr">
          {{ millisToDate(data.item.endDate) }}
        </div>
      </template>

      <template class="tr" #cell(developType)="data">
        <div class="tr" v-if="data.item.developType === 0">Текущая</div>
        <div class="tr" v-if="data.item.developType === 1">Развития</div>
         <div class="tr" v-if="data.item.developType === 3">Не определен</div>
        <div
          class="tr"
          v-if="
            data.item.developType === null &&
            data.item.type !== 1 &&
            data.item.type !== 2 &&
            data.item.type !== 3
          "
        >
          Не определен
        </div>
      </template>

      <template class="tr" #cell(budgetLevelId)="data">
        <div class="tr" v-if="data.item.budgetLevelId === 4">Бюджет МСУ</div>
        <div class="tr" v-if="data.item.budgetLevelId === 3">Рай.бюджет</div>
        <div class="tr" v-if="data.item.budgetLevelId === 2">ОБ</div>
        <div class="tr" v-if="data.item.budgetLevelId === 1">РБ</div>
        <div class="tr" v-if="data.item.budgetLevelId === 0">Не определен</div>
      </template>

      <template #cell(add)="data">
        <b-dropdown id="dropdown-dropleft" dropleft class="more">
          <template v-slot:button-content>
            <i class="icon icon-more"></i>
          </template>
          <template>
            <b-dropdown-item :disabled="!UserRoles.includes('dict_editor')" @click="openModalAndUpdateForm(data.item)"
              >Редактировать</b-dropdown-item
            >
            <!-- <b-dropdown-item
              v-if="data.item.type !== 1 && data.item.type !== 2"
              @click="openModalSave(data.item)"
              >Добавить
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="
                data.item.type !== 1 &&
                data.item.type !== 5
              "
               :disabled="!UserRoles.includes('dict_editor')" @click="openModalSaveChild(data.item)"
              >Добавить (дочерний)
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      @hidden="CleanItem"
      id="modal-prevent-closing"
      ref="my-modal"
      centered
      size="lg"
      content-class="modal-forms"
      title="Добавить / Редактировать"
    >
      <form class="sign-up" @submit.prevent="checkModalForm">
        <b-form-group
          disabled
          style="color: rgb(8, 2, 1)"
          label="Код функциональной группы (ФГ)"
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-input
            :disabled="
              (NewItem.id !== undefined && NewItem.gr !== undefined) ||
              NewItem.type === 2 ||
              NewItem.type === 3 ||
              NewItem.type === 4 ||
              NewItem.type === 5
                ? true
                : false
            "
            type="number"
            v-model="NewItem.gr"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            class="form-input"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          style="color: rgb(8, 2, 1)"
          label="Код функциональной подгруппы (ФПГ)"
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-input
            :disabled="
              (NewItem.id !== undefined && NewItem.pgr !== undefined) ||
              NewItem.type === 3 ||
              NewItem.type === 4 ||
              NewItem.type === 5 ||
              NewItem.type === 1
                ? true
                : false
            "
            type="number"
            v-model="NewItem.pgr"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            class="form-input"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          style="color: rgb(8, 2, 1)"
          label="Код администратора бюджетных программ (АБП)"
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-input
            :disabled="
              (NewItem.id !== undefined && NewItem.type === 3) ||
              NewItem.type === 2 ||
              NewItem.type === 4 ||
              NewItem.type === 5 ||
              NewItem.type === 1
                ? true
                : false
            "
            debounce="500"
            type="number"
            v-model="NewItem.abp"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            class="form-input"
            :class="$v.NewItem.abp.$error ? 'is-invalid' : ''"
          ></b-form-input>
          <p
            v-if="$v.NewItem.abp.$dirty && !$v.NewItem.abp.required"
            class="invalid-feedback"
          >
            Обязательное поле
          </p>
          <p
            v-if="$v.NewItem.abp.$dirty && !$v.NewItem.abp.isUnique"
            class="invalid-feedback"
          >
            Данный код администратора бюджетных программ существует !
          </p>

          <p
            v-if="$v.NewItem.abp.$dirty && !$v.NewItem.abp.minLength"
            class="invalid-feedback"
          >
            Число в поле должно быть трехзначным !
          </p>

          <p
            v-if="$v.NewItem.abp.$dirty && !$v.NewItem.abp.maxLength"
            class="invalid-feedback"
          >
            Число в поле должно быть трехзначным !
          </p>
        </b-form-group>

        <b-form-group
          style="color: rgb(8, 2, 1)"
          label="Код бюджетной программы (БП)"
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-input
            :disabled="
              (NewItem.id !== undefined && NewItem.type === 4) ||
              NewItem.type === 2 ||
              NewItem.type === 3 ||
              NewItem.type === 5 ||
              NewItem.type === 1
                ? true
                : false
            "
            type="number"
             debounce="500"
            v-model="NewItem.prg"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            :class="$v.NewItem.prg.$error ? 'is-invalid' : ''"
            class="form-input"
          ></b-form-input>
          <p
            v-if="$v.NewItem.prg.$dirty && !$v.NewItem.prg.required"
            class="invalid-feedback"
          >
            Обязательное поле
          </p>

          <p
            v-if="$v.NewItem.prg.$dirty && !$v.NewItem.prg.minLength"
            class="invalid-feedback"
          >
            Число в поле должно содержать от двух, до трех символов !
          </p>

          <p
            v-if="$v.NewItem.prg.$dirty && !$v.NewItem.prg.maxLength"
            class="invalid-feedback"
          >
            Число в поле должно содержать от двух, до трех символов !
          </p>

          <p
            v-if="$v.NewItem.prg.$dirty && !$v.NewItem.prg.isUniquePrg"
            class="invalid-feedback"
          >
            Данный код бюджетной программы существует !
          </p>
        </b-form-group>

        <b-form-group
          style="color: rgb(8, 2, 1)"
          label=" Код бюджетной подпрограммы (БПП)"
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-input
            :disabled="
              (NewItem.id !== undefined && NewItem.type === 5) ||
              NewItem.type === 2 ||
              NewItem.type === 3 ||
              NewItem.type === 4 ||
              NewItem.type === 1
                ? true
                : false
            "
            type="number"
             debounce="500"
            v-model="NewItem.ppr"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            :class="$v.NewItem.ppr.$error ? 'is-invalid' : ''"
            class="form-input"
          >
          </b-form-input>
          <p
            v-if="$v.NewItem.ppr.$dirty && !$v.NewItem.ppr.required"
            class="invalid-feedback"
          >
            Обязательное поле
          </p>

          <p
            v-if="$v.NewItem.ppr.$dirty && !$v.NewItem.ppr.minLength"
            class="invalid-feedback"
          >
            Число в поле должно содержать от двух, до трех символов !
          </p>

          <p
            v-if="$v.NewItem.ppr.$dirty && !$v.NewItem.ppr.maxLength"
            class="invalid-feedback"
          >
            Число в поле должно содержать от двух, до трех символов !
          </p>

          <p
            v-if="$v.NewItem.ppr.$dirty && !$v.NewItem.ppr.isUniquePpr"
            class="invalid-feedback"
          >
            Данный код бюджетной программы существует !
          </p>
        </b-form-group>

        <b-form-group
          v-if="NewItem.id !== undefined"
          style="color: rgb(8, 2, 1)"
          label="Код"
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-input
            type="text"
            v-model="NewItem.fullCode"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            class="form-input"
            :disabled="
              NewItem.id !== undefined ||
              NewItem.type === 1 ||
              NewItem.type === 2 ||
              NewItem.type === 3 ||
              NewItem.type === 4 ||
              NewItem.type === 5
                ? true
                : false
            "
          ></b-form-input>
        </b-form-group>

        <b-form-group
          style="color: rgb(8, 2, 1)"
          label="Наименование на Русском языке "
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-textarea
            v-model.trim="NewItem.name_ru"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            :class="$v.NewItem.name_ru.$error ? 'is-invalid' : ''"
            class="form-input"
          >
          </b-form-textarea>
          <p
            v-if="$v.NewItem.name_ru.$dirty && !$v.NewItem.name_ru.required"
            class="invalid-feedback"
          >
            Обязательное поле
          </p>
        </b-form-group>

        <b-form-group
          style="color: rgb(8, 2, 1)"
          label="Наименование на Казахском языке"
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-textarea
            v-model.trim="NewItem.name_kk"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            :class="$v.NewItem.name_kk.$error ? 'is-invalid' : ''"
            class="form-input"
          ></b-form-textarea>
          <p
            v-if="$v.NewItem.name_kk.$dirty && !$v.NewItem.name_kk.required"
            class="invalid-feedback"
          >
            Обязательное поле
          </p>
        </b-form-group>

        <b-form-group
          style="color: rgb(8, 2, 1)"
          label="Дата начала"
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-input
            type="date"
            v-model="NewItem.begDate"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            class="form-input"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          style="color: rgb(8, 2, 1)"
          label="Дата завершения"
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-input
            type="date"
            v-model="NewItem.endDate"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            class="form-input"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          style="color: rgb(8, 2, 1)"
          label="Уровень бюджета"
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-select
            v-model="NewItem.budgetLevelId"
            :disabled="
              NewItem.type == !3 ||
              NewItem.type === 2 ||
              NewItem.type === 1 ||
              NewItem.type === 4 ||
              NewItem.type === 5
                ? true
                : false
            "
            class="mb-3"
          >
            <b-form-select-option :value="0">Не выбрано</b-form-select-option>
            <b-form-select-option :value="1">РБ</b-form-select-option>
            <b-form-select-option :value="2">ОБ</b-form-select-option>
            <b-form-select-option :value="3">Рай.бюджет</b-form-select-option>
            <b-form-select-option :value="4">Бюджет МСУ</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          style="color: rgb(8, 2, 1)"
          label="Признак БП"
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-select
            v-model="NewItem.developType"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            class="form-input"
            :disabled="NewItem.type !== 4 ? true : false"
          >
            <b-form-select-option :value="1">Развития</b-form-select-option>
            <b-form-select-option :value="0">Текущая</b-form-select-option>
            <b-form-select-option v-if="NewItem.id === undefined" value="null"
              >Не определен</b-form-select-option
            >
            <b-form-select-option v-if="NewItem.id !== undefined" :value="3"
              >Не определен</b-form-select-option
            >
          </b-form-select>
        </b-form-group>

        <b-form-group
          v-if="!NewItem.id"
          style="color: rgb(8, 2, 1)"
          label="Коротко на Казахском языке "
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-textarea
            v-model.trim="NewItem.shortNameKk"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            class="form-input"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          v-if="!NewItem.id"
          style="color: rgb(8, 2, 1)"
          label="Коротко на Русском языке "
          label-for="title-input"
          label-align-sm="left"
          class="form-flex"
        >
          <b-form-textarea
            v-model.trim="NewItem.shortNameRu"
            id="title-input"
            no-resize
            rows="2"
            max-rows="2"
            class="form-input"
          ></b-form-textarea>
        </b-form-group>
      </form>

      <template #modal-footer>
        <b-button type="submit" variant="success" @click="checkModalForm">
          Сохранить
        </b-button>
        <b-button variant="danger" @click="hideModal">Закрыть</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/services/store';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import data from '../demo-budget-execution/data';
export default {
    props: {
        dictTree: null
    },
    mixins: [validationMixin],
    data() {
        return {
            UserRoles: store.state.user.roles,
            dictTreeLocal: JSON.parse(JSON.stringify(this.dictTree)),

            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'gr',
                    label: 'ФГ'
                },
                {
                    key: 'pgr',
                    label: 'ФПГ'
                },
                {
                    key: 'abp',
                    label: 'АБП'
                },
                {
                    key: 'prg',
                    label: 'БП'
                },
                {
                    key: 'ppr',
                    label: 'БПП'
                },
                {
                    key: 'name_ru',
                    label: 'языке-Рус'
                },

                {
                    key: 'name_kk',
                    label: 'Наименование на казахском'
                },
                {
                    key: 'begDate',
                    label: 'Дата начала'
                },

                {
                    key: 'endDate',
                    label: 'Дата завершения'
                },

                {
                    key: 'budgetLevelId',
                    label: 'Уровень бюджета'
                },

                {
                    key: 'developType',
                    label: 'Признак БП'
                },

                {
                    key: 'isSequest',
                    label: 'Признак'
                },

                {
                    key: 'add',
                    label: ' '
                },

                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],

            selectedTree: [],
            NewItem: {},
            OriginItem: {},
            ItemForUpDate: {},
            open: false,
            hasDescription: false,
            isUnique: false,
            SearchFilter: [],
            Search: '',

            filter: {
                gr: '',
                pgr: '',
                abp: '',
                prg: '',
                ppr: '',
                name_ru: '',
                budgetLevelId: '',
                search: '',
                on: ['budgetLevelId']

            }
        };
    },

    created() {
        this.$watch('filter.gr', this.filterUpdate);
        this.$watch('filter.pgr', this.filterUpdate);
        this.$watch('filter.abp', this.filterUpdate);
        this.$watch('filter.prg', this.filterUpdate);
        this.$watch('filter.ppr', this.filterUpdate);
        this.$watch('filter.name_ru', this.filterUpdate);
        this.$watch('filter.name_ru', this.filterUpdate);
        this.$watch('filter.budgetLevelId', this.filterUpdate);
            },

    validations() {
        if (
            this.NewItem.type === 1
      || this.NewItem.type === 2
      || this.NewItem.id !== undefined
        ) {
            return {
                NewItem: {
                    abp: {},
                    prg: {},
                    ppr: {},
                    name_ru: { required },
                    name_kk: { required }
                }
            };
        }
        if (this.NewItem.type === 3) {
            return {
                NewItem: {
                    abp: {
                        required,
                        minLength: minLength(3),
                        maxLength: maxLength(3),
                        isUnique(value) {
                            fetch('/api/dict/ebk_func_types/abp/child?gr='
            + this.NewItem.gr
            + '&pgr='
            + this.NewItem.pgr
            + '&abp='
            + value
            + '&type='
            + this.NewItem.type)
                                .then((response) => {
                                    return response.json();
                                })
                                .then((Data) => {
                                    if (Data.length === 0 || Data.length === undefined) {
                                        this.isUnique = true;
                                    } else {
                                        this.isUnique = false;
                                    }
                                });
                            return this.isUnique;
                        }
                    },
                    prg: {},
                    ppr: {},
                    name_ru: { required },
                    name_kk: { required }
                }
            };
        }
        if (this.NewItem.type === 4) {
            return {
                NewItem: {
                    abp: {},
                    prg: {
                        required,
                        isUniquePrg(value) {
                            fetch('/api/dict/ebk_func_types/prg/child/within-period?gr='
            + this.NewItem.gr
            + '&pgr='
            + this.NewItem.pgr
            + '&abp='
            + this.NewItem.abp
             + '&prg='
            + value
            + '&type='
            + this.NewItem.type
            + '&begDate='
            + this.NewItem.begDate
            )
                                .then((response) => {
                                    return response.json();
                                })
                                .then((Data) => {
                                    if (Data.length === 0 || Data.length === undefined) {
                                        this.isUnique = true;
                                    } else {
                                        this.isUnique = false;
                                    }
                                });
                            return this.isUnique;
                        },

                        minLength: minLength(2),
                        maxLength: maxLength(3)
                    },
                    ppr: {},
                    name_ru: { required },
                    name_kk: { required }
                }
            };
        }

        if (this.NewItem.type === 5) {
            return {
                NewItem: {
                    abp: {},
                    prg: {},
                    ppr: {
                        required,
                        isUniquePpr(value) {
                            fetch('/api/dict/ebk_func_types/ppr/child/within-period?gr='
            + this.NewItem.gr
            + '&pgr='
            + this.NewItem.pgr
            + '&abp='
            + this.NewItem.abp
            + '&prg='
            + this.NewItem.prg
            + '&ppr='
            + value
            + '&type='
            + this.NewItem.type
            + '&begDate='
            + this.NewItem.begDate
            )
                                .then((response) => {
                                    return response.json();
                                })
                                .then((Data) => {
                                    if (Data.length === 0 || Data.length === undefined) {
                                        this.isUnique = true;
                                    } else {
                                        this.isUnique = false;
                                    }
                                });
                            return this.isUnique;
                        },

                        minLength: minLength(2),
                        maxLength: maxLength(3)
                    },
                    name_ru: { required },
                    name_kk: { required }
                }
            };
        }

        if (this.NewItem.type === undefined) {
            return {
                NewItem: {
                    abp: {},
                    prg: {},
                    ppr: {},
                    name_ru: {},
                    name_kk: {}
                }
            };
        }
    },

    methods: {
        checkModalForm() {
            this.$v.NewItem.$touch();
            if (!this.$v.NewItem.$error) {
                this.SaveOrUpdate();
            }
        },

        SaveOrUpdate: function () {
            if (this.NewItem.id === undefined) {
                this.saveToServer();
            } else {
                this.updateItem();
            }
        },

        Resetload: function () {
            this.$emit('Resetload');
        },

        CleanItem: function () {
            this.NewItem = {};
            this.OriginItem = {};
            this.ItemForUpDate = {};
        },
        async updateItem() {
            if (this.NewItem.ppr === false || this.NewItem.ppr === true) {
                this.NewItem.ppr = null;
            }

            if (this.NewItem.prg === false || this.NewItem.prg === true) {
                this.NewItem.prg = null;
            }

            const response = await fetch('/api/dict/ebk_func_types/saveAll', {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                body: JSON.stringify(this.NewItem)
            })
            
            if (response.ok) {
              this.makeToast('Обьект удачно обновлен', 'success');
              Object.assign(this.ItemForUpDate, this.NewItem);
            } else {
              let text = 'Ошибка обновления!'
              const errorResponse = await response.json()
              const errorMessage = errorResponse?.args[0]?.value[0]
              if (errorMessage) {
                text = errorMessage
              }
              this.makeToast(text, 'danger')
              return
            }

            if (
                this.NewItem.endDate !== this.OriginItem.endDate
        && this.NewItem.type === 3
            ) {
                this.UpDateChildrenAbp(this.NewItem);
            }

            if (
                this.NewItem.budgetLevelId !== this.OriginItem.budgetLevelId
        && this.NewItem.type === 3
            ) {
                this.UpDateChildrenLevelId(this.NewItem);
            }

            if (
                this.NewItem.developType !== this.OriginItem.developType
        && this.NewItem.type === 4
            ) {
                this.UpDateChildrenDevelopType(this.NewItem);
            }

            if (this.OriginItem.open === true) {
                this.openChilds(this.OriginItem);
                setTimeout(this.openChilds, 500, this.OriginItem);
            }
            this.CleanItem();
            this.hideModal();
            // this.Resetload();
        },
        makeToast(title, variant) {
            this.$bvToast.toast(title, {
                title: 'Сообщение',
                variant: variant,
                solid: true
            });
        },
        async saveToServer() {
            switch (this.NewItem.type) {
                case 3:
                    this.NewItem.fullCode = this.NewItem.abp + '000000';
                    break;
                case 4:
                    if (String(this.NewItem.prg).length === 2) {
                        this.NewItem.fullCode
              = this.NewItem.abp + '0' + this.NewItem.prg + '000';
                        break;
                    } else if (String(this.NewItem.prg).length === 3) {
                        this.NewItem.fullCode = this.NewItem.abp + this.NewItem.prg + '000';
                        break;
                    }
                    break;

                case 5:
                    if (
                        String(this.NewItem.prg).length === 2
            && String(this.NewItem.ppr).length === 2
                    ) {
                        this.NewItem.fullCode
              = this.NewItem.abp
              + '0'
              + this.NewItem.prg
              + '0'
              + this.NewItem.ppr;
                        break;
                    } else if (
                        String(this.NewItem.prg).length === 2
            && String(this.NewItem.ppr).length === 3
                    ) {
                        this.NewItem.fullCode
              = this.NewItem.abp + '0' + this.NewItem.prg + this.NewItem.ppr;
                        break;
                    } else if (
                        String(this.NewItem.prg).length === 3
            && String(this.NewItem.ppr).length === 2
                    ) {
                        this.NewItem.fullCode
              = this.NewItem.abp
              + String(this.NewItem.prg)
              + '0'
              + this.NewItem.ppr;
                        break;
                    } else if (
                        String(this.NewItem.prg).length === 3
            && String(this.NewItem.ppr).length === 3
                    ) {
                        this.NewItem.fullCode
              = String(this.NewItem.abp)
              + String(this.NewItem.prg)
              + String(this.NewItem.ppr);
                        break;
                    }

                    break;
                default:
            }

            //id родительского элемента для контроля по датам начала/окончания
            let parentIdQuery = ''
            if (this.NewItem.type == 5) {
              parentIdQuery = '?parentId=' + this.OriginItem.id
            }

            const response = await fetch('/api/dict/ebk_func_types/save' + parentIdQuery, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(this.NewItem)
            })
            
            if (response.ok) {
              this.makeToast('Обьект удачно сохранен', 'success')
            } else {
              let text = 'Ошибка обновления!'
              const errorResponse = await response.json()
              const errorMessage = errorResponse?.args[0]?.value[0]
              if (errorMessage) {
                text = errorMessage
              }
              this.makeToast(text, 'danger')
              return
            }

            if (this.OriginItem.open === true) {
                this.openChilds(this.OriginItem);
                setTimeout(this.openChilds, 500, this.OriginItem);
            } else { setTimeout(this.openChilds, 500, this.OriginItem); }


            this.CleanItem();
            this.hideModal();
            // this.Resetload();
        },
        async UpDateChildrenAbp(Parent) {
            await fetch(
                '/api/dict/ebk_func_types/endDate/update?id='
          + Parent.id
          + '&endDate='
          + Parent.endDate,
                {
                    method: 'PUT'
                }
            );
        },
        async UpDateChildrenLevelId(Parent) {
            await fetch(
                '/api/dict/ebk_func_types/LevelId/update?id='
          + Parent.id
          + '&budgetLevelId='
          + Parent.budgetLevelId,
                {
                    method: 'PUT'
                }
            );
        },

        async UpDateChildrenDevelopType(Parent) {
            await fetch(
                '/api/dict/ebk_func_types/developType/update?id='
          + Parent.id
          + '&developType='
          + Parent.developType,
                {
                    method: 'PUT'
                }
            );
        },

        openModalSave(item) {
            this.CleanItem();
            this.OriginItem = JSON.parse(JSON.stringify(item));
            this.creatItemToSave(item);
            this.$v.NewItem.$touch();
            this.showModal();
        },

        openModalSaveChild(item) {
            this.CleanItem();
            this.OriginItem = item;
            this.creatItemToSaveChils(item);
            this.$v.NewItem.$touch();
            this.showModal();
        },

        creatItemToSave(item) {
            switch (item.type) {
                case 3:
                    this.NewItem.gr = item.gr;
                    this.NewItem.pgr = item.pgr;
                    this.NewItem.type = item.type;
                    break;
                case 4:
                    this.NewItem.gr = item.gr;
                    this.NewItem.pgr = item.pgr;
                    this.NewItem.abp = item.abp;
                    this.NewItem.budgetLevelId = item.budgetLevelId;
                    this.NewItem.endDate = item.endDate;
                    this.NewItem.type = item.type;
                    break;

                case 5:
                    this.NewItem.gr = item.gr;
                    this.NewItem.pgr = item.pgr;
                    this.NewItem.abp = item.abp;
                    this.NewItem.endDate = item.endDate;
                    this.NewItem.budgetLevelId = item.budgetLevelId;
                    this.NewItem.developType = item.developType;
                    this.NewItem.prg = item.prg;
                    this.NewItem.type = item.type;
                    break;
                default:
            }
        },

        creatItemToSaveChils(item) {
            switch (item.type) {
                case 2:
                    this.NewItem.gr = item.gr;
                    this.NewItem.pgr = item.pgr;
                    this.NewItem.endDate = item.endDate;
                    this.NewItem.type = item.type + 1;

                    break;
                case 3:
                    this.NewItem.gr = item.gr;
                    this.NewItem.pgr = item.pgr;
                    this.NewItem.abp = item.abp;
                    this.NewItem.endDate = item.endDate;
                    this.NewItem.budgetLevelId = item.budgetLevelId;
                    this.NewItem.type = item.type + 1;

                    break;
                case 4:
                    this.NewItem.gr = item.gr;
                    this.NewItem.pgr = item.pgr;
                    this.NewItem.abp = item.abp;
                    this.NewItem.prg = item.prg;
                    this.NewItem.endDate = item.endDate;
                    this.NewItem.budgetLevelId = item.budgetLevelId;
                    this.NewItem.developType = item.developType;
                    this.NewItem.type = item.type + 1;
                    break;

                default:
            }
        },

        openModalAndUpdateForm(item) {
            this.ItemForUpDate = item;
            item.begDate = this.millisToDate(item.begDate);
            item.endDate = this.millisToDate(item.endDate);
            this.NewItem = JSON.parse(JSON.stringify(item));
            this.OriginItem = JSON.parse(JSON.stringify(item));
            this.showModal();
        },
        showModal() {
            this.$refs['my-modal'].show();
        },
        hideModal() {
            this.$refs['my-modal'].hide();
        },

        async filterUpdate() {
            this.filter.search
        = (this.filter.gr === null ? '' : this.filter.gr)
        + (this.filter.pgr === null ? '' : this.filter.pgr)
        + (this.filter.abp === null ? '' : this.filter.abp)
        + (this.filter.ppr === null ? '' : this.filter.ppr)
        + (this.filter.prg === null ? '' : this.filter.prg)
        + (this.filter.name_ru === null ? '' : this.filter.name_ru)
        + (this.filter.budgetLevelId === null ? '' : this.filter.budgetLevelId);
            this.SearchFilter = [];
            this.dictTreeLocal = [];
            const response = await fetch('/api/dict/ebk_func_types/filter?abp='
             + this.filter.abp
             + '&prg='
             + this.filter.prg
             + '&ppr='
              + this.filter.ppr);

            this.SearchFilter = await response.json();
            this.SearchFilter.filter(function (item) {
                item.open = false;
                item.visible = true;
            });
            this.dictTreeLocal = this.SearchFilter;
            if (this.filter.search.length === 0) {
                this.filter.search = null;
                this.dictTreeLocal = JSON.parse(JSON.stringify(this.dictTree));
            }
        },

        async loadParentFetch(Parent) {
            if (Parent.open === true && Parent.type === 1) {
                const response = await fetch(
                    '/api/dict/ebk_func_types/gr/child?gr=' + Parent.gr + '&type=' + 2
                );
                this.selectedTree = await response.json();
                this.selectedTree.forEach(function (child) {
                    child.open = false;
                    child.visible = true;
                });

                const searchIndex = Parent.id;
                const index = this.dictTreeLocal.findIndex(
                    (el) => el.id === searchIndex
                );
                this.selectedTree.forEach((data) => {
                    this.dictTreeLocal.splice(index + 1, 0, data);
                });
            } else if (Parent.open === false && Parent.type === 1) {
                this.dictTreeLocal = this.dictTreeLocal.filter(
                    (child) =>
                        !(child.gr === Parent.gr && child.type === 2)
            && child.type !== 3
            && child.type !== 4
            && child.type !== 5
                );
            }

            if (Parent.open === true && Parent.type === 2) {
                const response = await fetch(
                    '/api/dict/ebk_func_types/pgr/child?gr='
            + Parent.gr
            + '&pgr='
            + Parent.pgr
            + '&type='
            + 3
                );
                this.selectedTree = await response.json();
                this.selectedTree.forEach(function (child) {
                    child.open = false;
                    child.visible = true;
                });
                const searchIndex = Parent.id;
                const index = this.dictTreeLocal.findIndex(
                    (el) => el.id === searchIndex
                );
                this.selectedTree.forEach((data) => {
                    this.dictTreeLocal.splice(index + 1, 0, data);
                });
            } else if (Parent.open === false && Parent.type === 2) {
                this.dictTreeLocal = this.dictTreeLocal.filter(
                    (child) =>
                        !(child.pgr === Parent.pgr && child.type === 3)
            && child.type !== 4
            && child.type !== 5
                );
            }
            if (Parent.open === true && Parent.type === 3) {
                const response = await fetch(
                    '/api/dict/ebk_func_types/abp/child?gr='
            + Parent.gr
            + '&pgr='
            + Parent.pgr
            + '&abp='
            + Parent.abp
            + '&type='
            + 4
                );
                this.selectedTree = await response.json();
                if (this.selectedTree.length !== 0) {
                    this.selectedTree.forEach(function (item) {
                        item.open = false;
                        item.visible = true;
                    });
                } else {
                    Parent.prg = false;
                }
                const searchIndex = Parent.id;
                const index = this.dictTreeLocal.findIndex(
                    (el) => el.id === searchIndex
                );
                this.selectedTree.forEach((data) => {
                    this.dictTreeLocal.splice(index + 1, 0, data);
                });
            } else if (Parent.open === false && Parent.type === 3) {
                this.dictTreeLocal = this.dictTreeLocal.filter(
                    (child) =>
                        !(child.abp === Parent.abp && child.type === 4) && child.type !== 5
                );
                Parent.prg = true;
            }

            if (Parent.open === true && Parent.type === 4) {
                const response = await fetch(
                    '/api/dict/ebk_func_types/prg/between-dates-child?gr='
            + Parent.gr
            + '&pgr='
            + Parent.pgr
            + '&abp='
            + Parent.abp
            + '&prg='
            + Parent.prg
            + '&type='
            + 5
            + '&begDate='
            + this.millisToDate(Parent.begDate)
            + '&endDate='
            + this.millisToDate(Parent.endDate)
                );

                this.selectedTree = await response.json();
                if (this.selectedTree.length !== 0) {
                    this.selectedTree.forEach(function (item) {
                        item.open = false;
                        item.visible = true;
                    });
                } else {
                    Parent.ppr = false;
                }

                const searchIndex = Parent.id;
                const index = this.dictTreeLocal.findIndex(
                    (el) => el.id === searchIndex
                );
                this.selectedTree.forEach((data) => {
                    this.dictTreeLocal.splice(index + 1, 0, data);
                });
            } else if (Parent.open === false && Parent.type === 4) {
                this.dictTreeLocal = this.dictTreeLocal.filter(
                    (child) => !(child.prg === Parent.prg && child.type === 5)
                );
                Parent.ppr = true;
            }
                    },

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }

            if (item.type === 2) {
                return ' table-primary';
            }

            if (item.type === 4) {
                return ' table-primary';
            }
        },

        openChilds(item) {
            if (item.open === false) {
                item.open = true;
                this.loadParentFetch(item);
                this.selectedTree = [];
            } else if (item.open === true) {
                item.open = false;
                this.loadParentFetch(item);
                this.selectedTree = [];
            }
        },

        millisToDate: function (millis) {
            if (!millis) {
                return '';
            }
            const date = new Date(millis);
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month.toString().length === 1) {
                month = '0' + month;
            }
            let day = date.getDate();
            if (day.toString().length === 1) {
                day = '0' + day;
            }
            return year + '-' + month + '-' + day;
        }
    }
};
</script>

<style>
.form-input,
.form-select {
  min-width: 545px;
  background: #ffffff;
  border: 1px solid #bcd2e0;
  border-radius: 66px;
  font-size: 15px;
}
</style>